import React from 'react';
import ReactDOM from 'react-dom';
import { Canvas } from 'react-three-fiber';
import * as serviceWorker from './serviceWorker';

import Clock from './app/Clock';

import './index.scss';
import DisplayClock from './app/displayClock/displayClock';

ReactDOM.render(
  <>
    <DisplayClock />

    <Canvas>
      <ambientLight />
      <pointLight position={[-7, 0, 7]} />

      <Clock />
    </Canvas>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
